.footer_container {
  padding: 3rem 10rem 3rem 10rem;
  /* padding-top: 3rem; */
  background-color: rgba(0, 0, 0, 1);
  display: flex;
  gap: 3rem;
}
.footer_col1 {
  flex: 3;
}
.footer_col2,
.footer_col3,
.footer_col4 {
  flex: 1.5;
}
.foot_col1_title {
  font-size: 5rem;
  margin-bottom: 1rem;
  text-align: left;
  font-family: "Amiri", serif;
  color: #fffffe;
}
.foot_col1_p {
  font-size: 2rem;
  text-align: left;
  font-weight: 500;
  color: #b8c1ec;
  line-height: 2.7rem;
  width: 80%;
  font-family: "Zen Kaku Gothic Antique", sans-serif;
}
.foot_bio {
  font-size: 2.1rem;
  font-weight: 600;
  font-family: "Architects Daughter", cursive;
  color: #797ec2;
}
.foot_col_head {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  margin-top: 2rem;
  text-align: center;
  font-family: "Amiri", serif;
  color: #fffffe;
}
.foot_col_li {
  display: list-item;
  font-family: "Zen Kaku Gothic Antique", sans-serif;
  margin: 2px 0px;
  font-size: 2rem;
  font-weight: 400;
}

.foot_col_a {
  display: block;
  color: #b8c1ec;
  font-family: "Zen Kaku Gothic Antique", sans-serif;
  margin: 2px 0px;
  font-size: 2rem;
  font-weight: 400;
}
.copyright {
  text-align: center;
}
.copright_p {
  font-size: 2rem;
  padding: 1rem 2rem;
  color: #797ec2;
  font-family: "Zen Kaku Gothic Antique", sans-serif;
}
.copyright_span,
.copyright_span1 {
  padding-left: 2rem;
}

@media only screen and (max-width: 1200px) {
  .footer_container {
    padding: 2.5rem 6rem;
    gap: 1rem;
  }
  .foot_col1_title {
    font-size: 4rem;
  }
  .foot_col1_p {
    font-size: 1.75rem;
    width: 100%;
  }
  .foot_bio {
    font-size: 1.8rem;
    width: 90%;
  }
  .foot_col_head {
    font-size: 2rem;
  }
  .foot_col_li {
    font-size: 1.75rem;
  }
  .foot_col_a {
    font-size: 1.7rem;
    margin: 1px 0px;
    padding: 0.3rem 1rem;
  }
  .foot_col_link {
    font-size: 1.7rem;
    padding: 0.3rem 1rem;
  }
  .footer_col1 {
    width: 90%;
  }
}
@media only screen and (max-width: 860px) {
  .footer_container {
    padding: 1.7rem 2rem;
    gap: 0.8rem;
  }
  .foot_col1_p {
    font-size: 1.7rem;
    margin-top: -1rem;
    font-weight: 600;
  }
  .footer_col2,
  .footer_col3,
  .footer_col4 {
    flex: none;
  }
}
@media only screen and (max-width: 766px) {
  .copright_p {
    font-size: 1.7rem;
    text-align: center;
    padding: 0.5rem 1.5rem;
  }
  .copyright_span {
    display: none;
  }
  .copyright_span1 {
    display: block;
    text-align: center;
    padding-left: 0rem;
  }
  .footer_container {
    flex-direction: column;
    gap: 0rem;
    padding: 0rem 1rem;
  }
  .footer_container > div {
    margin-top: 2rem;
  }
  .footer_col1 {
    max-width: 100%;
  }
  .foot_col_head {
    text-align: left;
    font-size: 2.5rem;
  }
  .foot_col_li {
    font-size: 2rem;
    text-align: left;
  }

  .footer_container {
    margin-top: 7rem;
  }
} ;
