* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
html {
  font-size: 10px;
  background-color: rgb(9, 8, 14);
}
ul,
li {
  list-style: none;
}
a {
  text-decoration: none;
}
img,
svg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
