.contact_container {
  padding: 7rem 15rem;
  position: relative;
  top: 7rem;
  margin-bottom: 6rem;
}
.contact_wrapper {
  background-color: rgba(0, 0, 0, 1);
  border-radius: 12px;
  padding: 3rem 2rem;
  text-align: center;
  font-weight: 600;
  font-family: "Architects Daughter", cursive;
}
.contact_wrapper_heading {
  font-size: 4rem;
  margin-bottom: 2.5rem;
  color: #fffffe;
  word-spacing: 1rem;
  font-family: "Amiri", serif;
}
.contact_wrapper_p {
  font-size: 2.5rem;
  /* margin-bottom: 1.5rem; */
  color: #b8c1ec;
}
.contact_wrapper_btn {
  margin: 1rem;
  border: 2px solid rgb(160, 158, 158);
  color: #fffffe;
  border-radius: 6px;
  font-size: 2.3rem;
  background-color: #ff8906;
  font-family: "Architects Daughter", cursive;
}
.contact_wrapper_btn:hover {
  color: white;
  font-weight: bold;
}
@media only screen and (max-width: 1100px) {
  .contact_wrapper {
    padding: 2.7rem 1.7rem;
  }
  .contact_wrapper_heading {
    font-size: 3.7rem;
    word-spacing: 0.7rem;
  }
  .contact_wrapper_p {
    font-size: 2.1rem;
  }
  .contact_container {
    padding: 5.5rem 13rem;
  }
}
@media only screen and (max-width: 860px) {
  .contact_wrapper {
    padding: 2.4rem 1.4rem;
  }
  .contact_wrapper_heading {
    font-size: 3.4rem;
    word-spacing: 0.7rem;
  }
  .contact_wrapper_p {
    font-size: 1.8rem;
  }
  .contact_container {
    padding: 4.5rem 11.5rem;

    top: 3rem;
    margin-bottom: 6rem;
  }
}
@media only screen and (max-width: 766px) {
  .contact_container {
    padding: 1rem 2rem;
  }
  .contact_wrapper_heading {
    font-size: 2.8rem;
    word-spacing: 0.6rem;
  }
  .contact_wrapper_p {
    font-size: 1.8rem;
  }
}
