.progress-heading {
  font-size: 4rem;
}
.progress-subheading {
  text-align: center;
  font-size: 4rem;
  color: #fffffe;
  font-family: "Architects Daughter", cursive;
}

.progress-title {
  color: #b8c1ec;
  font-size: 2rem;
  font-family: "Zen Kaku Gothic Antique", sans-serif;
  margin-bottom: 1rem;
}
.progress-bar {
  background-color: #b8c1ec;
  border-radius: 20px;
  position: relative;
  /* margin: 15px 0; */
  height: 25px;
  width: 100%;
}
.progress-done {
  background: linear-gradient(to left, #fcc485, #ff8906);
  background-color: #070707;
  /* box-shadow: 0 3px 3px -5px #eebbc3, 0 2px 5px #eebbc3; */
  border-radius: 20px;
  color: #ffffff;
  font-size: 1.9rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 0;
  opacity: 0;
}
.skills-wrapper .progress-subheading {
  font-size: 2.5rem;
}
.progress-wrapper {
  margin: 0.5rem 2rem;
}
.progresses-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 1rem;
}
.align-front {
  text-align: left;
  padding-left: 3rem;
}
.align-back {
  text-align: right;
  padding-right: 3rem;
}
.skills-container {
  margin: 5rem;
}
.skills-heading {
  font-size: 5rem;
  color: white;
  font-family: "Amiri", serif;
  text-align: center;
  margin-top: 3rem;
}
.skillsicon {
  width: 9rem;
}
.skills-wrapper {
  margin: 5rem;
}
.skills-carousel {
  margin: 1rem;
  margin-top: 12rem;
  background-color: black;
  padding: 3rem 0rem;
}
.bwgQCB {
  width: 97% !important;
}

.skillpng-title {
  font-size: 2.5rem;
  color: #b8c1ec;
  margin-bottom: 1rem;
  text-align: center;
}
.skillsicon {
  height: 70%;
}
.rec.rec-arrow {
  border-radius: 0;
  background-color: transparent !important;
}

.rec.rec-arrow:hover {
  border-radius: 50% !important;
  background-color: #ff8906 !important;
}
.rec.rec-arrow:active {
  background-color: #ff8906 !important;
}
.rec.rec-arrow:disabled {
  visibility: hidden;
}
.rec-dot {
  background-color: rgb(48, 47, 47) !important;
}
.rec-dot_active {
  background-color: #ff8906 !important;
  border: none !important;
  box-shadow: none !important;
}
@media only screen and (max-width: 1100px) {
  .skills-heading {
    font-size: 4rem;
  }
  .align-front {
    font-size: 2.5rem;
  }
  .align-back {
    font-size: 2.5rem;
  }
}
@media only screen and (max-width: 920px) {
  .skills-container {
    margin: 5rem 1rem;
  }
  .progress-wrapper {
    margin: 1rem;
  }
  .progresses-wrapper {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
}

@media only screen and (max-width: 920px) {
  .skills-carousel {
    padding: 2rem 0rem;
  }
  .rec.rec-arrow {
    position: relative;
    top: 1rem;
  }
  .skillpng-title {
    font-size: 2rem;
  }
  .skillsicon {
    width: 7rem;
  }
}
@media only screen and (max-width: 480px) {
  .align-front {
    padding-left: 1rem;
  }
  .align-back {
    padding-right: 0rem;
  }
  .skills-carousel {
    margin-top: 7rem;
  }
  .skillpng-title {
    font-size: 1.7rem;
  }
}
