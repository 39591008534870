.navmenu {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1rem 0;
  background-color: rgb(9, 8, 14);
  font-family: "Amiri", serif;
  z-index: 100;
}
svg {
  color: #ff8906;
}
ul {
  max-width: 1200px;
  margin: 0 auto;
  width: 90%;
  text-align: center;
}

li {
  display: inline-block;
  border-radius: 8px;
  transition: 0.5s ease background-color;
}
li:hover {
  background-color: black;
}
a {
  color: #b1b1b0;
  padding: 1rem 2rem;
  font-size: 2.3rem;
  outline: none;
}
.active {
  color: #fffffe;
}
.navbaropen {
  position: absolute;
  right: 1rem;
  top: 1rem;
  width: 4rem;
  cursor: pointer;
  display: none;
  outline: none;
}
.navbarclose {
  display: none;
}
@media only screen and (max-width: 780px) {
  .navmenu {
    height: 5rem;
  }
  .navbaropen {
    display: block;
    margin: 0 auto;
  }
  .home {
    animation-duration: 1s;
    animation-name: slidein;
    animation-direction: alternate;
  }

  .about {
    animation-duration: 1.5s;
    animation-name: slidein;
    animation-direction: alternate;
  }

  .skills {
    animation-duration: 2s;
    animation-name: slidein;
    animation-direction: alternate;
  }

  .projects {
    animation-duration: 2.5s;
    animation-name: slidein;
    animation-direction: alternate;
  }

  .contact {
    animation-duration: 3s;
    animation-name: slidein;
    animation-direction: alternate;
  }
  @keyframes slidein {
    from {
      margin-left: 100%;
      width: 0%;
    }

    to {
      margin-left: 0%;
      width: 100%;
    }
  }
  .navbarclose {
    display: block;
    width: 4rem;
    cursor: pointer;
    margin: 0 0 0 auto;
    position: absolute;
    right: 0rem;
    top: 0rem;
  }
  .navitems {
    padding: 2rem;
    background-color: #181818ee;
    position: absolute;
    right: 1rem;
    top: 1rem;
    width: 90%;
    max-width: 300px;
    border-radius: 12px;
  }
  li {
    display: block;
    margin-bottom: 1rem;
  }
  .hide-items {
    display: none;
  }
}
