h1 {
  font-size: 4rem;
  color: #fffffe;
  font-family: "Amiri", serif;
}
/* for Home Page  */
.mine {
  height: 100vh;
  min-height: 1000px;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.my_heading {
  font-size: 2rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
  position: relative;
}
.uni {
  display: inline-block;
  width: 100%;
  position: relative;
  top: 4.5rem;
  font-size: 2.5rem;
  color: #a7a9be;
}
.uni_type {
  font-size: 7rem;
  font-family: "Amiri", serif;
  color: #fffffe;
  position: relative;
  top: 1rem;
}
.text_type {
  font-size: 3.3rem;
  font-family: "Architects Daughter", cursive;
  z-index: 1;
  color: #e0dcdc;
  /* color: white; */
}
.home_bg_img {
  max-width: 1000px;
  width: 100%;
  height: 650px;
  margin: 0 auto;
  border: 3px solid #b8c1ec;
  position: relative;
  top: -6rem;
  z-index: -1;
}
.home_content {
  color: #b8c1ec;
  max-width: 800px;
  text-align: center;
  margin: 0 auto;
  font-weight: 600;
  position: relative;
  top: -3rem;
  font-size: 2rem;
  line-height: 2.8rem;
  font-family: "Zen Kaku Gothic Antique", sans-serif;
}
.home_bio {
  font-family: "Architects Daughter", cursive;
  font-size: 2.5rem;
  color: #fffffe;
}
.follow_home,
.scroll_arrow {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  position: absolute;
  bottom: 20px;
  width: 50px;
}

.follow_home {
  left: 60px;
  z-index: 10;
}
.scroll_arrow {
  right: 60px;
}
.social_text,
.scroll_text {
  font-size: 1.7rem;
  font-family: "Zen Kaku Gothic Antique", sans-serif;
  transform: translateY(-90px) rotate(90deg);
  color: #fffffe;
  letter-spacing: 1rem;
  text-transform: uppercase;
  font-weight: 600;
}
.arrow {
  max-height: 50px;
  width: 18px;
  margin: 0 auto;
  object-fit: contain;
}
.ar1 {
  max-height: 90px;
}
.icon {
  display: inline-block;
  width: 30px;
  position: relative;
  left: -10px;
  transition: transform 1.5s;
  /* transform: rotate(90deg); */
}
.icon:hover {
  /* transform: rotate(360deg); */
  transform: scale(1.6);
}
/* hii */
.div_btn_home {
  margin-top: 1rem;
}
.btn_home {
  background-color: #ff8906;
  font-size: 2.3rem;
  padding: 1.1rem 2.2rem;
  border-radius: 7px;
  display: inline-block;
  border: 2px solid #b8c1ec;
  color: #fffffe;
  font-family: "Architects Daughter", cursive;
}
.btn_home:hover {
  color: #b8c1ec !important;
  font-weight: bold;
}

@media only screen and (max-width: 1100px) {
  .uni_type {
    font-size: 6rem;
  }
  .uni {
    font-size: 2.2rem;
    top: 3.5rem;
  }
  .text_type {
    font-size: 3rem;
  }
  .mine {
    align-items: start;
  }
  .social_text,
  .scroll_text {
    font-size: 1.5rem;
    letter-spacing: 0.8rem;
  }
  .follow_home,
  .scroll_arrow {
    gap: 1.7rem;
    bottom: 16px;
    width: 40px;
  }
  .follow_home {
    left: 45px;
    bottom: 200px;
  }
  .scroll_arrow {
    right: 45px;
  }
  .icon {
    width: 25px;
    right: 1px;
    transition: transform 1.5s;
  }
  .icon:hover {
    transform: scale(1.4);
  }
  .home_bg_img {
    max-width: 800px;
    width: 95%;
    height: 500px;
    border: 2px solid #a7a9be;
    top: -6rem;
  }
  .home_content {
    max-width: 700px;
    top: -2.5rem;
    font-size: 1.7rem;
    line-height: 2.3rem;
  }
  .home_bio {
    font-size: 2.2rem;
  }
  .btn_home {
    font-size: 2.1rem;
    padding: 1rem 2rem;
    border-radius: 5px;
  }
}
@media only screen and (max-width: 860px) {
  .social_text,
  .scroll_text {
    font-size: 1.3rem;
    transform: translateY(-70px) rotate(90deg);
    letter-spacing: 0.8rem;
    font-weight: 600;
  }
  .follow_home,
  .scroll_arrow {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    position: absolute;
    bottom: 15px;
    width: 40px;
  }
  .follow_home {
    left: 7px;
    bottom: 150px;
  }
  .scroll_arrow {
    right: 5px;
    bottom: 160px;
  }
  .icon {
    display: inline-block;
    width: 25px;
    position: relative;
    right: 3px;
    transition: transform 1.5s;
  }
  .home_bg_img {
    max-width: 750px;
    width: 90%;
    top: -5.5rem;
  }
  .home_content {
    max-width: 650px;
  }
}
@media only screen and (max-width: 766px) {
  .mine {
    min-height: 750px;
  }
  .my_heading {
    font-size: 1.4rem;
    margin-bottom: 2.5rem;
  }
  .uni_type {
    font-size: 4rem;
    top: 1.5rem;
  }
  .text_type {
    font-size: 2.7rem;
    z-index: 1;
    position: relative;
    top: 1rem;
    /* color: white; */
  }
  .home_bg_img {
    width: 90%;
    height: 300px;
  }
  .home_content {
    max-width: 320px;
    font-size: 1.2rem;
    line-height: 1.8rem;
  }
  .home_bio {
    max-width: 320px;
    font-size: 1.6rem;
    position: relative;
    margin: 0 auto;
    top: -2rem;
  }
  .btn_home {
    font-size: 1.7rem;
    padding: 0.7rem 1.5rem;
    border-radius: 5px;
    position: relative;
    top: -2.5rem;
    z-index: 100;
  }
  a.btn_home:hover {
    color: #b8c1ec;
  }
  /* helloooooo */
  .follow_home {
    left: 1.5rem;
    bottom: -13%;
    width: 30px;
    gap: 1rem;
  }
  .arrow {
    max-height: 25px;
  }
  .icon {
    width: 1.6rem;
    margin-bottom: 1rem;
  }

  .link {
    padding: 2px 20px;
  }
  .social_text {
    font-size: 1.2rem;
    letter-spacing: 0.6rem;
  }
}
/* for About Section */
.about_container {
  /* min-height: 800px; */
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  position: relative;
  top: 5rem;
}
.about_info {
  margin: 2rem 0rem;
  display: flex;
  justify-content: space-evenly;
  text-align: left;
  position: relative;
  top: 5rem;
}
.about_p {
  width: 100%;
  display: inline-block;
  position: relative;
  top: 3rem;
  font-size: 2.5rem;
  text-align: left;
  color: #b8c1ec;
  font-weight: 700;
  font-family: "Architects Daughter", cursive;
}
.about_h2 {
  margin-top: 0.5rem;
  position: relative;
  color: #fffffe;
  top: 3rem;
  font-size: 6rem;
  text-transform: capitalize;
  text-align: left;
  font-family: "Amiri", serif;
}
.about_lt,
.about_rt {
  flex: 1;
}
.about_lt {
  max-width: 600px;
  position: relative;
  left: 2rem;
}
.about_rt {
  max-width: 600px;
}
.about_content {
  margin: 0rem;
  position: relative;
  text-align: left;
  top: 6rem;
  max-width: 500px;
  font-size: 2rem;
  font-weight: 600;
  line-height: 3rem;
  text-align: left;
  color: #b8c1ec;
  font-family: "Zen Kaku Gothic Antique", sans-serif;
}
.about_sec_btns {
  margin-top: 3rem;
}
.about_sec_btn {
  display: inline-flex;
  justify-content: space-evenly;
  position: relative;
  top: 3rem;
  margin: 1rem;
  /* border: 2px solid #b8c1ec; */
  border-radius: 7px;
  font-size: 1.2rem;
  font-family: "Architects Daughter", cursive;
}
.btn_about_work {
  padding: 0.2rem 0.8rem;
  background-color: #ff8906;
  color: #fffffe;
  border: 2px solid #b8c1ec;
  border-radius: 7px;
  /*     background-color: #ff8906;
    font-size: 2.3rem;
    padding: 1.1rem 2.2rem;
    border-radius: 7px;
    display: inline-block; */
}
.btn_about_work:hover {
  color: #b8c1ec;
  font-weight: bold;
}
.btn_about {
  padding: 0.2rem 0.8rem;
  background-color: transparent;
  color: #fffffe;
  border: 2px solid #b8c1ec;
  border-radius: 7px;
}
.btn_about:hover {
  background-color: #ff8906;
  color: black;
}
.about_img {
  max-width: 700px;
  width: 100%;
  height: 450px;
  margin: 0 auto 0 0;
  border: 3px solid #b8c1ec;
  position: relative;
  right: 1.5rem;
}
@media only screen and (max-width: 1100px) {
  .about_p {
    font-size: 2rem;
  }
  .about_container {
    top: -15rem;
  }
  .about_h2 {
    font-size: 4.5rem;
  }
  .about_lt,
  .about_rt {
    max-width: 450px;
  }
  .about_content {
    font-size: 1.7rem;
    line-height: 2.4rem;
    top: 5rem;
    max-width: 400px;
    font-weight: 600;
  }
  .about_sec_btns {
    margin-top: 3rem;
  }
  .about_sec_btn {
    top: 3rem;
    margin: 0.8rem;
    font-size: 1rem;
    border-radius: 4px;
  }
  .about_img {
    max-width: 400px;
    height: 350px;
    right: -2.5rem;
    top: 2rem;
  }
}
@media only screen and (max-width: 860px) {
  .about_p {
    font-size: 1.7rem;
  }
  /* .about_container {
    top: -4rem;
  } */
  .about_h2 {
    font-size: 4rem;
  }
  .about_lt,
  .about_rt {
    max-width: 375px;
  }
  .about_content {
    font-size: 1.7rem;
    line-height: 2.1rem;
    max-width: 350px;
  }
  .about_img {
    max-width: 350px;
    height: 300px;
    right: -2.5rem;
  }
}
@media only screen and (max-width: 766px) {
  .about_container {
    top: -7rem;
  }
  .about_info {
    top: 1rem;
    display: list-item;
  }
  .about_lt,
  .about_rt {
    flex: 0;
    left: 0rem;
  }
  .about_p {
    width: 100%;
    text-align: center;
    font-size: 2.5rem;
  }
  .about_h2 {
    font-size: 4rem;
    text-align: center;
  }
  .about_content {
    text-align: center;
    top: 3rem;
    font-size: 1.2rem;
    line-height: 1.8rem;
  }
  .about_sec_btn {
    display: flex;
    margin: 2rem 5rem;
  }
  .about_img {
    margin: 0 auto;
    right: 0rem;
    top: 3rem;
  }
}

/* for About Page */
.about-container {
  padding: 10rem 0rem;
}
.top-section {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0rem 5rem;
  gap: 2rem;
}
.left-part {
  flex: 3;
}
.right-part {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 2;
}
.about-subheading {
  width: 100%;
  display: inline-block;
  position: relative;
  top: 1rem;
  font-size: 3rem;
  text-align: left;
  color: #b8c1ec;
  font-weight: 700;
  margin-bottom: 2rem;
}
.about-span {
  background-color: black;
  color: #fffffe;
  padding: 0.5rem;
  border-radius: 4px;
  font-size: 4rem;
}
.about-heading {
  margin-top: 0.5rem;
  position: relative;
  top: 2rem;
  font-size: 5rem;
  color: #fffffe;
  text-align: left;
  font-family: "Amiri", serif;
}
.about-info {
  margin-bottom: 4rem;
}
.about-content {
  max-width: 100%;
  margin: 0rem;
  position: relative;
  text-align: left;
  top: 6rem;
  font-size: 2rem;
  font-weight: 600;
  line-height: 3rem;
  text-align: left;
  color: #b8c1ec;
  font-family: "Zen Kaku Gothic Antique", sans-serif;
}
.right-part > img {
  max-width: 600px;
  margin: 6rem 4rem;
  width: 70%;
  height: 80%;
  border: 3px solid #a7a9be;
}
.download_cv {
  display: flex;
  justify-content: center;
}
.download_cv_btn {
  background-color: #ff8906;
  font-size: 2.3rem;
  padding: 1.1rem 2.2rem;
  border-radius: 7px;
  display: inline-block;
  border: 2px solid rgb(160, 158, 158);
  color: white;
  font-family: "Architects Daughter", cursive;
}
.download_cv_btn:hover {
  font-weight: bold;
}
.mid-section {
  padding: 0rem 6rem;
}
.about-info-heading {
  margin-top: 2.5rem;
  font-size: 3.5rem;
  color: #fffffe;
  text-align: left;
  font-family: "Amiri", serif;
}
.info-item-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 3rem;
}
.info-item-title {
  font-size: 2.5rem;
  color: #b8c1ec;
  font-family: "Architects Daughter", cursive;
}

.info-item-items {
  display: flex;
  gap: 1.5rem;
  position: absolute;
  left: 23rem;
}
.info-item {
  background-color: black;
  padding: 1rem;
  margin-left: 1.5rem;
  border-radius: 7px;
  font-size: 2rem;
  color: #a7a9be;
  font-family: "Zen Kaku Gothic Antique", sans-serif;
}
/* last */
.service_p {
  position: relative;
  top: 1rem;
  right: 3rem;
  font-size: 2.5rem;
  text-align: center;
  color: #a7a9be;
  font-weight: 700;
  font-family: "Architects Daughter", cursive;
}
.service_h2 {
  margin-top: 0.5rem;
  position: relative;
  top: -1rem;
  right: 3rem;
  font-size: 6rem;
  text-transform: capitalize;
  text-align: center;
  font-family: "Amiri", serif;
}
.services_all {
  display: flex;
  gap: 5rem;
  justify-content: space-between;
  margin-top: -1rem;
}
.service_wrapper {
  text-align: center;
  padding: 2rem;
}
.serviceicon {
  width: 4rem;
}
.servicestitle {
  font-family: "Architects Daughter", cursive;
  color: #959ad4;
}
.service_h3 {
  font-size: 3rem;
}
.servicecontent {
  font-family: "Zen Kaku Gothic Antique", sans-serif;
  font-size: 1.9rem;
  color: #a7a9be;
}

@media only screen and (max-width: 1100px) {
  .about-container {
    padding-top: 6rem;
  }
  .top-section {
    padding: 0rem 3rem;
  }
  .left-part {
    flex: 4;
  }
  .service_h2 {
    font-size: 5rem;
  }
  .about-subheading {
    font-size: 2.7rem;
  }
  .about-heading {
    font-size: 4rem;
  }
  .about-span {
    font-size: 3.5rem;
  }
  .about-content {
    font-size: 1.7rem;
    line-height: 2.5rem;
  }
  .right-part > img {
    width: 90%;
    margin-top: 10rem;
  }
  .download_cv_btn {
    font-size: 2rem;
  }
  .mid-section {
    padding: 0rem 4rem;
  }
  .about-info-heading {
    font-size: 3.2rem;
  }
  .info-item-title {
    font-size: 2.25rem;
  }
  .info-item-items {
    left: 20rem;
  }
  .info-item {
    font-size: 1.75rem;
  }
  .service_p {
    font-size: 2rem;
  }
  .service_h3 {
    font-size: 2.5rem;
  }
  .servicecontent {
    font-size: 1.7rem;
  }
  .services_all {
    gap: 3rem;
  }
}
@media only screen and (max-width: 860px) {
  .service_p {
    font-size: 2rem;
  }
  .top-section {
    padding: 0rem 2rem;
  }
  .about-subheading {
    font-size: 2.4rem;
  }
  .about-heading {
    font-size: 3.4rem;
  }
  .about-content {
    font-size: 1.5rem;
    line-height: 2.2rem;
  }
  .right-part > img {
    width: 100%;
    margin-top: 10rem;
  }
  .download_cv_btn {
    font-size: 1.7rem;
  }
  .about-info-heading {
    font-size: 2.9rem;
  }
  .info-item-title {
    font-size: 2rem;
  }
  .info-item-items {
    left: 18rem;
  }
  .info-item {
    font-size: 1.75rem;
  }
  .service_h2 {
    font-size: 4rem;
  }
  .service_h3 {
    font-size: 2rem;
  }
  .servicecontent {
    font-size: 1.6rem;
  }
  .services_all {
    gap: 1rem;
  }
  .service_wrapper {
    padding: 0.7rem;
  }
}
@media only screen and (max-width: 766px) {
  .top-section {
    flex-direction: column;
    gap: 4rem;
    padding: 0rem 4rem;
  }
  .about-subheading {
    font-size: 2.1rem;
  }
  .about-heading {
    font-size: 3.1rem;
  }
  .about-content {
    font-size: 1.5rem;
    line-height: 2.4rem;
  }
  .right-part > img {
    width: 80%;
    margin-top: 5rem;
  }
  .info-item-wrapper {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
  .info-item-items {
    position: initial;
    gap: 0.5rem;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }
  .info-item-title {
    font-size: 2.25rem;
  }
  .info-item {
    font-size: 1.5rem;
  }
  .services_all {
    flex-direction: column;
    max-width: 350px;
    margin: 0 auto;
    gap: 2rem;
    margin-top: 1rem;
    margin-bottom: 3rem;
  }
  .service_h2,
  .service_p {
    right: 0rem;
  }
  .servicecontent {
    font-size: 1.5rem;
  }
}
@media only screen and (max-width: 480px) {
  .top-section {
    padding: 0rem 1.5rem;
  }
  .about-heading {
    font-size: 2.6rem;
  }
  .download_cv_btn {
    font-size: 1.5rem;
  }
  .right-part > img {
    width: 90%;
    margin: 3rem 3rem 5rem 3rem;
  }
  .about-info-heading {
    font-size: 2.5rem;
  }
  .info-item {
    margin-left: 0rem;
  }
  .mid-section {
    padding: 1rem;
  }
  .about-info-heading:first-child {
    margin-top: 0rem;
  }
}
/* Contact Page */
.con-wrapper {
  padding: 1.5rem 3rem;
  background-color: black;
  display: flex;
  align-items: center;
  gap: 2rem;
  border-radius: 8px;
  margin-bottom: 2rem;
  margin-left: 5rem;
}
.contactinfoitems {
  display: block;
  align-items: center;
  justify-content: center;
}
.con-icon {
  padding: 1.3rem;
  align-items: center;
  justify-content: center;
}
.contacticon {
  width: 4rem;
}
.con-form {
  width: 100%;
  margin-bottom: 2rem;
}
label {
  font-size: 2.5rem;
  color: #b8c1ec;
  font-family: "Architects Daughter", cursive;
}
input,
textarea {
  width: 100%;
  font-size: 2.2rem;
  padding: 1.2rem;
  color: #b8c1ec;
  font-family: "Zen Kaku Gothic Antique", sans-serif;
  background-color: black;
  outline: none;
  border: none;
  border-radius: 8px;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
textarea {
  min-height: 250px;
  resize: vertical;
}
.form-btn {
  background-color: #ff8906;
  color: white;
  border: 2px solid #a7a9be;
  font-size: 2.2rem;
  font-family: "Architects Daughter", cursive;
  display: inline-block;
  position: relative;
  left: 13rem;
  border-radius: 7px;
  padding: 1rem 4rem;
  cursor: pointer;
  margin: 3rem;
}
.form-btn:hover {
  font-weight: bold;
}
.contact-sec {
  display: flex;
  gap: 5rem;
  margin: 6rem 7rem 3rem 7rem;
  justify-content: space-around;
  position: relative;
}
.contact-sec::after {
  position: absolute;
  content: "";
  width: 2px;
  height: 50%;
  background-color: #a7a9be;
  left: 49%;
  top: 30%;
  transform: translate(-50%, -50%);
}
.contact-left {
  width: 100;
  max-width: 500px;
}
.contact-right {
  width: 100%;
  max-width: 500px;
}
.contact-p {
  color: #b8c1ec;
  text-align: center;
  font-family: "Architects Daughter", cursive;
  font-size: 3rem;
  margin-top: 3rem;
}
.contact-h2 {
  color: white;
  text-align: center;
  font-family: "Amiri", serif;
  font-size: 6rem;
}
.con-p {
  font-size: 2.5rem;
  color: #b8c1ec;
  font-family: "Zen Kaku Gothic Antique", sans-serif;
}
.error-msg {
  font-size: 1.5rem;
  color: #b8c1ec;
  font-family: "Zen Kaku Gothic Antique", sans-serif;
}
/* ui for contact page */
@media only screen and (max-width: 1100px) {
  .form-btn {
    left: 10rem;
  }
  .con-wrapper {
    margin-left: 0rem;
    padding: 1rem 2.5rem;
  }
  .contact-sec::after {
    left: 51%;
  }
  .contact-p {
    font-size: 2.7rem;
  }
  .contact-h2 {
    font-size: 5rem;
  }
  .con-p {
    font-size: 2.3rem;
  }
  label {
    font-size: 2.3rem;
  }
  input,
  textarea {
    font-size: 2rem;
  }
}

@media only screen and (max-width: 920px) {
  .contact-sec::after {
    left: 57%;
  }
}
@media only screen and (max-width: 860px) {
  .contactinfoitems {
    margin-top: 3rem;
  }
  .contact-sec {
    margin: 6rem 2rem 4rem 2rem;
  }
  .form-btn {
    left: 5rem;
  }
  .contact-sec::after {
    left: 53%;
  }
  .contact-p {
    font-size: 2.5rem;
  }
  .contact-h2 {
    font-size: 4.5rem;
  }
  .con-p {
    font-size: 2.1rem;
  }
  label {
    font-size: 2.1rem;
  }
  input,
  textarea {
    font-size: 1.8rem;
  }
}
@media only screen and (max-width: 768px) {
  .contact-sec {
    flex-direction: column;
    margin: 0rem;
  }
  .con-wrapper {
    margin-right: 2rem;
    margin-left: 1.5rem;
    padding: 0rem;
  }
  .contact-sec::after {
    display: none;
  }
  .contact-h2 {
    font-size: 4rem;
  }
  .contact-p {
    font-size: 2rem;
  }
  .contact-left,
  .contact-right {
    max-width: 100%;
  }
  .contact-right {
    padding: 3rem 2rem 2rem 2rem;
  }
}
@media only screen and (max-width: 480px) {
  .contact-sec {
    flex-direction: column;
    margin: 0rem;
  }
  .con-wrapper {
    margin-right: 1rem;
    margin-left: 0.7rem;
    padding: 0rem;
    margin-bottom: 1.5rem;
  }
  .con-icon {
    padding: 0.7rem;
  }
  .con-p {
    font-size: 1.7rem;
  }
  .contact-h2 {
    font-size: 3.5rem;
  }
  .contact-p {
    font-size: 1.8rem;
  }
  .contact-left,
  .contact-right {
    max-width: 100%;
  }
  .contact-right {
    padding: 1rem;
  }
  .form-btn {
    left: 7rem;
  }
}
/* Map Contact */
.map-card-heading {
  font-size: 3rem;
  margin-bottom: 0.5rem;
  color: #b8c1ec;
}
.map-card-p {
  font-size: 2rem;
  font-family: "Zen Kaku Gothic Antique", sans-serif;
  color: #b8c1ec;
  word-spacing: 0.7rem;
}
/* Skills section */
.skills_container {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 15rem;
}
.skills-sec-wrapper {
  display: flex;
}
.skills-sec-content {
  max-width: 500px;
  position: relative;
  left: -10rem;
  top: 3rem;
}
.skills-sec-subhead {
  width: 100%;
  display: inline-block;
  position: relative;
  top: 2rem;
  font-size: 2.5rem;
  text-align: left;
  color: #b8c1ec;
  font-weight: 700;
  font-family: "Architects Daughter", cursive;
}
.skills-sec-bar {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 1rem;
  max-width: 600px;
  position: relative;
  left: 7%;
  top: 10%;
}
.skills-sec-h2 {
  font-size: 6rem;
  font-family: "Amiri", serif;
  margin-top: 0.5rem;
  position: relative;
  color: #fffffe;
  top: 1rem;
  text-transform: capitalize;
  text-align: left;
}
.skills-sec-p {
  font-size: 2rem;
  font-weight: 600;
  line-height: 3rem;
  color: #b8c1ec;
  font-family: "Zen Kaku Gothic Antique", sans-serif;
}
.btn-skills {
  padding: 0.2rem 0.8rem;
  background-color: transparent;
  color: #fffffe;
  border: 2px solid hsl(230, 58%, 82%);
  border-radius: 7px;
  font-family: "Architects Daughter", cursive;
  position: relative;
  left: 10rem;
  top: 3rem;
}
.btn-skills:hover {
  color: black;
  background-color: #ff8906;
}
.skills-sec-btn {
  top: 5rem;
  margin: 0.8rem;
  font-size: 1rem;
  border-radius: 4px;
}
@media only screen and (max-width: 1100px) {
  .skills-sec-bar {
    left: 5%;
    max-width: 400px;
  }
  .skills_container .progress-bar {
    height: 20px;
    margin: 0;
  }
  .skills-sec-content {
    left: 6rem;
    top: 1rem;
  }
  .skills-sec-subhead {
    font-size: 2rem;
  }
  .skills_container {
    margin-top: 0rem;
    position: relative;
  }
  .skills-sec-h2 {
    font-size: 4.5rem;
  }

  .skills-sec-p {
    font-size: 1.7rem;
    line-height: 2.4rem;
    font-weight: 600;
  }
}

@media only screen and (max-width: 860px) {
  .skills-sec-subhead {
    font-size: 1.8rem;
  }
  .skills-sec-h2 {
    font-size: 4rem;
  }
  .skills-sec-content {
    max-width: 400px;
    left: 3rem;
  }
  .skills-sec-bar {
    left: 3%;
    max-width: 350px;
  }
}
@media only screen and (max-width: 766px) {
  .skills-sec-wrapper {
    display: inline-block;
    position: relative;
    top: 35rem;
  }

  .skills-sec-subhead {
    font-size: 2.5rem;
    text-align: center;
  }
  .skills-sec-content {
    left: 1rem;
    max-width: 3oopx;
  }

  .skills-sec-h2 {
    text-align: center;
  }
  .skills-sec-bar {
    left: 1.5%;
    max-width: 300px;
  }
}
/* project Section */
.project-sec-container {
  position: relative;
  top: 7rem;
}
.dOBxuW .projectItem-img {
  padding: 0.5rem 2rem !important;
}
.button-wrapper {
  margin-top: 3rem;
}
.button-demo,
.button-source {
  display: inline-flex;
  justify-content: space-evenly;
  position: relative;
  top: 0.5rem;
  margin: 1rem;
  cursor: pointer;
  /* border: 2px solid #b8c1ec; */
  border-radius: 7px;
  font-size: 1.2rem;
  font-family: "Architects Daughter", cursive;
}
.btn-demo {
  padding: 0.2rem 0.8rem;
  background-color: #ff8906;
  color: #fffffe;
  border: 2px solid #b8c1ec;
  border-radius: 7px;
}
.btn-demo:hover {
  color: #b8c1ec;
  font-weight: bold;
}
.btn-source {
  padding: 0.2rem 0.8rem;
  background-color: transparent;
  color: #fffffe;
  border: 2px solid #b8c1ec;
  border-radius: 7px;
}
.btn-source:hover {
  background-color: #ff8906;
  color: black;
}

@media only screen and (max-width: 860px) {
  .project-sec-container {
    top: 3rem;
  }
}

@media only screen and (max-width: 768px) {
  .about_info {
    top: 15rem;
  }
  .skills_container {
    margin-top: 7rem;
  }
  .skills-sec-content {
    max-width: 360px;
  }
}

@media only screen and (max-width: 540px) {
  .follow_home {
    left: 2rem;
    bottom: -13%;
    width: 30px;
    grid-gap: 1rem;
    gap: 1rem;
  }
  .scroll_arrow {
    bottom: -100px;
  }
  .about_container {
    top: -20rem;
  }
  .skills_container {
    top: -35rem;
    margin-top: 0rem;
  }
  .project-sec-container {
    top: 7rem;
  }
  .contact_wrapper {
    position: relative;
    top: 3rem;
  }
}
@media only screen and (max-width: 480px) {
  .follow_home {
    bottom: -5rem;
    left: 1rem;
  }
  .home_content {
    font-size: 1.6rem;
    font-weight: 600;
    max-width: 300px;
    line-height: 2rem;
  }
  .about_content {
    font-weight: 600;
    font-size: 1.6rem;
  }
  .scroll_arrow {
    bottom: 3rem;
    right: 0px;
  }
  /* .about_container {
    top: -27rem;
  } */
  /* .skills_container {
    top: -50rem;
  }
  .project-sec-container {
    top: 0;
  } */
}
@media only screen and (max-width: 400px) {
  .follow_home {
    bottom: -12rem;
    left: 0.5rem;
  }
  .social_text,
  .scroll_text {
    font-size: 0.9rem;
  }
  .about_info {
    top: 20rem;
  }
  /* .about_container {
    top: -23rem;
  } */
  .skills_container {
    top: -35rem;
    /* .about_info {
    top: 6rem;
  } */
  }
}
/* @media only screen and (max-width: 376px) {
  .about_container {
    top: -10rem;
  }
  .skills_container {
    top: -35rem;
  }
  .project-sec-container {
    top: 5rem;

    font-family: cursive;
  }
} */
